<template>
  <div class="about">
    <el-table
        :data="tableData"
        size="medium"
        height="calc(100vh - 150px)"
        row-key="id"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column
          prop="name"
          label="部门名称">
      </el-table-column>
      <el-table-column
          prop="text"
          width="180"
          label="操作">
        <template #header>
          <el-button  @click="add()" size="mini" type="primary" style="margin:10px 0;">新增</el-button>
        </template>
        <template #default="scope">
          <div style="margin: 5px 0">
            <el-button-group>
              <el-button size="mini" type="primary" icon="el-icon-circle-plus-outline" @click="add(scope.row,'nowadd')"></el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
              <el-button size="mini"  type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
            </el-button-group>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer
        :title="'分组'+(ruleForm.id?'新增':'修改')"
        v-model="drawer"
        size="30%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm">
        <el-form-item label="部门名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="上级名称" prop="father_id">
          <el-cascader
              placeholder=""
              ref="cascaderHandle"
              style="width: 100%"
              :options="areaArr"
              v-model="ruleForm.father_id"
              :props="{
                          label: 'name',
                          value: 'id',
                          children: 'children',
                          checkStrictly: true
                          }"
              :filterable="true">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import {getArea, getCharge} from "@/api/common";
import * as axios from "@/api/Api"//本页面接口
export default {
  name: 'brand',
  data(){
    return{
      login:false,
      drawer:false,
      tableData:[],
      fatherArr:[],
      areaArr:[],
      statusArr:[
        {
          id:0,
          name:'待开通',
        },
        {
          id:1,
          name:'已开通',
        },
        {
          id:2,
          name:'已停止',
        },
      ],

      ruleForm:{
        name:'',
        father_id:'',
        adcode:'',
        status:0,
        deposit:0,
      },
      rules:{
        name: [
          { required: true, message: '请填写', trigger: 'blue' },

        ],
        father_id: [
          { required: true, message: '请选择', trigger: 'blue' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    // this.onArea()
  },
  methods:{
    // onArea(){
    //   getArea().then((res)=>{
    //     this.areaArr=res.data
    //     this.areaArr.unshift({
    //       name:'顶层',
    //       id:0
    //     })
    //   }).catch((err)=>{
    //     this.$message.error('服务器报错！！请联系管理员')
    //   })
    // },
    onTable(){
      axios.get('','/api/admin/woker_group').then((res)=>{
        console.log(res)
        this.tableData=res.data
        this.areaArr=JSON.parse(JSON.stringify(res.data))
        this.areaArr.unshift({
          name:'顶层',
          id:0
        })
        console.log('this.tableData',this.tableData)
      }).catch((err)=>{

      })
    },

    add(row,val){
      this.ruleForm= {
        name:'',
        father_id:'',
      }
      if(row){
        this.ruleForm.father_id=row.id
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.father_id=row.father_id

      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.post(this.ruleForm,'/api/admin/woker_group/add').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                  that.login=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.post(this.ruleForm,'/api/admin/woker_group/edit').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          this.drawer=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.post({id:row.id},'/api/admin/woker_group/del').then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
  padding: 0;
}
</style>